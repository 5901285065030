export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const USER_DETAILS_FETCH_REQUEST = 'USER_DETAILS_FETCH_REQUEST';
export const USER_DETAILS_FETCH_SUCCESS = 'USER_DETAILS_FETCH_SUCCESS';
export const USER_DETAILS_FETCH_FAILED = 'USER_DETAILS_FETCH_FAILED';

export const OSA_USER_DETAILS_FETCH_REQUEST = 'OSA_USER_DETAILS_FETCH_REQUEST';
export const OSA_USER_DETAILS_FETCH_SUCCESS = 'OSA_USER_DETAILS_FETCH_SUCCESS';
export const OSA_USER_DETAILS_FETCH_FAILED = 'OSA_USER_DETAILS_FETCH_FAILED';

export const USER_DETAILS_UPDATE_REQUEST = 'USER_DETAILS_UPDATE_REQUEST';
export const USER_DETAILS_UPDATE_SUCCESS = 'USER_DETAILS_UPDATE_SUCCESS';
export const USER_DETAILS_UPDATE_FAILED = 'USER_DETAILS_UPDATE_FAILED';

export const PEOPLE_DETAILS_FETCH_REQUEST = 'PEOPLE_DETAILS_FETCH_REQUEST';
export const PEOPLE_DETAILS_FETCH_SUCCESS = 'PEOPLE_DETAILS_FETCH_SUCCESS';
export const PEOPLE_DETAILS_FETCH_FAILED = 'PEOPLE_DETAILS_FETCH_FAILED';

export const POST_FETCH_REQUEST = 'POST_FETCH_REQUEST';
export const POST_FETCH_SUCCESS = 'POST_FETCH_SUCCESS';
export const POST_FETCH_FAILED = 'POST_FETCH_FAILED';

export const POST_NEXT_FETCH_REQUEST = 'POST_NEXT_FETCH_REQUEST';
export const POST_NEXT_FETCH_SUCCESS = 'POST_NEXT_FETCH_SUCCESS';
export const POST_NEXT_FETCH_FAILED = 'POST_NEXT_FETCH_FAILED';

export const POST_DETAILS_FETCH_REQUEST = 'POST_DETAILS_FETCH_REQUEST';
export const POST_DETAILS_FETCH_SUCCESS = 'POST_DETAILS_FETCH_SUCCESS';
export const POST_DETAILS_FETCH_FAILED = 'POST_DETAILS_FETCH_FAILED';

export const POST_CREATE_REQUEST = 'POST_CREATE_REQUEST';
export const POST_CREATE_SUCCESS = 'POST_CREATE_SUCCESS';
export const POST_CREATE_FAILED = 'POST_CREATE_FAILED';

export const USER_POST_FETCH_REQUEST = 'USER_POST_FETCH_REQUEST';
export const USER_POST_FETCH_SUCCESS = 'USER_POST_FETCH_SUCCESS';
export const USER_POST_FETCH_FAILED = 'USER_POST_FETCH_FAILED';

export const USER_FOLLOWERS_FETCH_REQUEST = 'USER_FOLLOWERS_FETCH_REQUEST';
export const USER_FOLLOWERS_FETCH_SUCCESS = 'USER_FOLLOWERS_FETCH_SUCCESS';
export const USER_FOLLOWERS_FETCH_FAILED = 'USER_FOLLOWERS_FETCH_FAILED';

export const USER_FOLLOW_REQUEST = 'USER_FOLLOW_REQUEST';
export const USER_FOLLOW_SUCCESS = 'USER_FOLLOW_SUCCESS';
export const USER_FOLLOW_FAILED = 'USER_FOLLOW_FAILED';

export const USER_UNFOLLOW_REQUEST = 'USER_UNFOLLOW_REQUEST';
export const USER_UNFOLLOW_SUCCESS = 'USER_UNFOLLOW_SUCCESS';
export const USER_UNFOLLOW_FAILED = 'USER_UNFOLLOW_FAILED';

export const LIKE_ADD_REQUEST = 'LIKE_ADD_REQUEST';
export const LIKE_ADD_SUCCESS = 'LIKE_ADD_SUCCESS';
export const LIKE_ADD_FAILED = 'LIKE_ADD_FAILED';

export const LIKE_DELETE_REQUEST = 'LIKE_DELETE_REQUEST';
export const LIKE_DELETE_SUCCESS = 'LIKE_DELETE_SUCCESS';
export const LIKE_DELETE_FAILED = 'LIKE_DELETE_FAILED';

export const VOTED_POST_FETCH_REQUEST = 'VOTED_POST_FETCH_REQUEST';
export const VOTED_POST_FETCH_SUCCESS = 'VOTED_POST_FETCH_SUCCESS';
export const VOTED_POST_FETCH_FAILED = 'VOTED_POST_FETCH_FAILED';

export const BOOK_SEARCH_REQUEST = 'BOOK_SEARCH_REQUEST';
export const BOOK_SEARCH_SUCCESS = 'BOOK_SEARCH_SUCCESS';
export const BOOK_SEARCH_FAILED = 'BOOK_SEARCH_FAILED';

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILED = 'SEARCH_FAILED';

export const MESSAGE_CREATE_REQUEST = 'MESSAGE_CREATE_REQUEST';
export const MESSAGE_CREATE_SUCCESS = 'MESSAGE_CREATE_SUCCESS';
export const MESSAGE_CREATE_FAILED = 'MESSAGE_CREATE_FAILED';

export const MESSAGE_FETCH_REQUEST = 'MESSAGE_FETCH_REQUEST';
export const MESSAGE_FETCH_SUCCESS = 'MESSAGE_FETCH_SUCCESS';
export const MESSAGE_FETCH_FAILED = 'MESSAGE_FETCH_FAILED';

export const NOTIFICATION_FETCH_REQUEST = 'NOTIFICATION_FETCH_REQUEST';
export const NOTIFICATION_FETCH_SUCCESS = 'NOTIFICATION_FETCH_SUCCESS';
export const NOTIFICATION_FETCH_FAILED = 'NOTIFICATION_FETCH_FAILED';

export const NOTIFICATION_NEXT_FETCH_REQUEST =
	'NOTIFICATION_NEXT_FETCH_REQUEST';
export const NOTIFICATION_NEXT_FETCH_SUCCESS =
	'NOTIFICATION_NEXT_FETCH_SUCCESS';
export const NOTIFICATION_NEXT_FETCH_FAILED = 'NOTIFICATION_NEXT_FETCH_FAILED';

export const NOTIFICATION_UPDATE_REQUEST = 'NOTIFICATION_UPDATE_REQUEST';
export const NOTIFICATION_UPDATE_SUCCESS = 'NOTIFICATION_UPDATE_SUCCESS';
export const NOTIFICATION_UPDATE_FAILED = 'NOTIFICATION_UPDATE_FAILED';

export const POST_DELETE_REQUEST = 'POST_DELETE_REQUEST';
export const POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS';
export const POST_DELETE_FAILED = 'POST_DELETE_FAILED';

export const POST_ARCHIVE_REQUEST = 'POST_ARCHIVE_REQUEST';
export const POST_ARCHIVE_SUCCESS = 'POST_ARCHIVE_SUCCESS';
export const POST_ARCHIVE_FAILED = 'POST_ARCHIVE_FAILED';

export const SKILL_CREATE_REQUEST = 'SKILL_CREATE_REQUEST';
export const SKILL_CREATE_SUCCESS = 'SKILL_CREATE_SUCCESS';
export const SKILL_CREATE_FAILED = 'SKILL_CREATE_FAILED';

export const SKILL_POSTS_FETCH_REQUEST = 'SKILL_POSTS_FETCH_REQUEST';
export const SKILL_POSTS_FETCH_SUCCESS = 'SKILL_POSTS_FETCH_SUCCESS';
export const SKILL_POSTS_FETCH_FAILED = 'SKILL_POSTS_FETCH_FAILED';

export const SKILL_POSTS_NEXT_FETCH_REQUEST = 'SKILL_POSTS_NEXT_FETCH_REQUEST';
export const SKILL_POSTS_NEXT_FETCH_SUCCESS = 'SKILL_POSTS_NEXT_FETCH_SUCCESS';
export const SKILL_POSTS_NEXT_FETCH_FAILED = 'SKILL_POSTS_NEXT_FETCH_FAILED';

export const SKILL_LIST_FETCH_REQUEST = 'SKILL_LIST_FETCH_REQUEST';
export const SKILL_LIST_FETCH_SUCCESS = 'SKILL_LIST_FETCH_SUCCESS';
export const SKILL_LIST_FETCH_FAILED = 'SKILL_LIST_FETCH_FAILED';

export const SKILL_LIST_SEARCH_REQUEST = 'SKILL_LIST_SEARCH_REQUEST';
export const SKILL_LIST_SEARCH_SUCCESS = 'SKILL_LIST_SEARCH_SUCCESS';
export const SKILL_LIST_SEARCH_FAILED = 'SKILL_LIST_SEARCH_FAILED';

export const USER_SKILLS_FETCH_REQUEST = 'USER_SKILLS_FETCH_REQUEST';
export const USER_SKILLS_FETCH_SUCCESS = 'USER_SKILLS_FETCH_SUCCESS';
export const USER_SKILLS_FETCH_FAILED = 'USER_SKILLS_FETCH_FAILED';

export const UPLOAD_REQUEST = 'UPLOAD_REQUEST';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILED = 'UPLOAD_FAILED';

export const SKILL_POST_DELETE_REQUEST = 'SKILL_POST_DELETE_REQUEST';
export const SKILL_POST_DELETE_SUCCESS = 'SKILL_POST_DELETE_SUCCESS';
export const SKILL_POST_DELETE_FAILED = 'SKILL_POST_DELETE_FAILED';

export const SKILL_SEARCH_REQUEST = 'SKILL_SEARCH_REQUEST';
export const SKILL_SEARCH_SUCCESS = 'SKILL_SEARCH_SUCCESS';
export const SKILL_SEARCH_FAILED = 'SKILL_SEARCH_FAILED';

export const PEOPLE_SEARCH_REQUEST = 'PEOPLE_SEARCH_REQUEST';
export const PEOPLE_SEARCH_SUCCESS = 'PEOPLE_SEARCH_SUCCESS';
export const PEOPLE_SEARCH_FAILED = 'PEOPLE_SEARCH_FAILED';

export const PEOPLE_SKILLS_FETCH_REQUEST = 'PEOPLE_SKILLS_FETCH_REQUEST';
export const PEOPLE_SKILLS_FETCH_SUCCESS = 'PEOPLE_SKILLS_FETCH_SUCCESS';
export const PEOPLE_SKILLS_FETCH_FAILED = 'PEOPLE_SKILLS_FETCH_FAILED';

export const POST_UPDATE_REQUEST = 'POST_UPDATE_REQUEST';
export const POST_UPDATE_SUCCESS = 'POST_UPDATE_SUCCESS';
export const POST_UPDATE_FAILED = 'POST_UPDATE_FAILED';

export const TOP_PEOPLE_REQUEST = 'TOP_PEOPLE_REQUEST';
export const TOP_PEOPLE_SUCCESS = 'TOP_PEOPLE_SUCCESS';
export const TOP_PEOPLE_FAILED = 'TOP_PEOPLE_FAILED';
